<template>
  <div class="point-container">
    <div class="search-box">
      <el-input
        placeholder="请输入查询的关键字"
        v-model="searchVal"
        maxlength="20"
        clearable>
      </el-input>
      <el-button type="primary" style="margin-left: 20px" @click="startSearch">查询</el-button>
    </div>

    <div></div>

    <el-divider v-if="list.length === 0">
      <div style="color: #333;">没有更多数据</div>
    </el-divider>

    <div v-for="item in list" :key="item._id">
      <div class="point-item">
        <div class="left">
          <div class="title" @click="toDetail(item._id)">{{ item.title }}</div>
          <div class="content">{{ item.description }}</div>
          <div class="control-btn">
            <!-- <i class="el-icon-view"></i>
            <span>7</span>
            <i class="el-icon-thumb"></i>
            <span>7</span> -->
          </div>
        </div>
        <div class="right">
          <img :src="item.cover" alt="">
        </div>
      </div>
      <el-divider></el-divider>
    </div>

    <div class="footer">
      <el-pagination
        layout="prev, pager, next"
        :page-size="5"
        :current-page.sync="page"
        @current-change="startSearch"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { Input, Button, Pagination, Divider, Loading } from 'element-ui'
import { getPointsUrl } from '@/assets/js/api'
export default {
  data() {
    return {
      searchVal: '',
      total: 0,
      page: 1,
      list: [],
      type: '',
    }
  },
  components: {
    'el-input': Input,
    'el-button': Button,
    'el-pagination': Pagination,
    'el-divider': Divider
  },
  mounted() {
    this.type = this.$route.params.type
    this.startSearch()
  },
  methods: {
    startSearch() {
      const loading = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$axios.post(getPointsUrl, {
        page: this.page,
        pageSize: 5,
        searchKey: this.searchVal,
        type: this.type
      }).then(res => {
        this.list = res.data.data
        this.total = res.data.num
        loading.close()
      }).catch(() => loading.close())
    },
    toDetail(id) {
      this.$router.push('/write/'+id)
    }
  }
}
</script>

<style lang="less" scoped>
.point-container {
  width: 80%;
  max-width: 800px;
  margin: 0 auto;

  .search-box {
    display: flex;
    margin: 20px auto;
  }

  .point-item {
    padding: 10px 0;
    display: flex;

    .left {
      flex: 1;

      .title {
        font-size: 18px;
        margin-bottom: 8px;
        cursor: pointer;
        height: 26px;
        display: inline-block;
        box-sizing: border-box;

        &:hover {
          border-bottom: 1px solid #333;
        }
      }

      .content {
        font-size: 13px;
        color: #999999;
        margin-bottom: 8px;
        padding-right: 50px;
        height: 40px;
      }

      .control-btn {
        color: #999999;
        font-size: 10px;

        span {
          padding: 0 8px 0 4px;
        }
      }
    }

    .right {
      width: 150px;
      height: 100px;
      border-radius: 3px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: center;
  }
}
</style>